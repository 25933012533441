import React, { useEffect, useState } from "react"

import { Turn as Hamburger } from "hamburger-react"
import MobileHeaderNavItem from "./MobileHeaderNavItem"
import SubMenuItem from "./SubMenuItem"
import { URLProvider } from "../../utils/URLProvider"

type MobileNavigationProps = {
    isMobileMenuOpened: boolean
    setIsMobileMenuOpened: React.Dispatch<React.SetStateAction<boolean>>
}

const MobileNavigation = ({
    isMobileMenuOpened: isMenuOpened,
    setIsMobileMenuOpened: setIsMenuOpened,
}: MobileNavigationProps) => {
    const [activeMenu, setActiveMenu] = useState(-1)

    useEffect(() => {
        if (isMenuOpened === false) {
            setActiveMenu(-1)
        }
    }, [isMenuOpened])

    const handleMenu = (index: number) => {
        if (activeMenu === index) {
            setActiveMenu(-1)
        } else {
            setActiveMenu(index)
        }
    }

    return (
        <div className="lg:hidden">
            <Hamburger
                hideOutline
                toggled={isMenuOpened}
                toggle={setIsMenuOpened}
                onToggle={(toggled) => {
                    if (toggled) {
                        setIsMenuOpened(true)
                    } else {
                        setIsMenuOpened(false)
                    }
                }}
                color={isMenuOpened ? "#0E9DE5" : "#FFFFFF"}
            />
            <div
                className={`fixed left-6 sm:left-16 top-[5.1rem] w-[calc(100vw-3rem)] sm:w-[calc(100vw-8rem)] border bg-horizen-hp-bg overflow-y-scroll max-h-[calc(100vh-150px)] md:h-auto transition-all duration-300
                ${isMenuOpened ? "opacity-100" : "opacity-0 invisible"}
                `}
            >
                <MobileHeaderNavItem
                    label="Docs"
                    href={URLProvider.URL_HORIZEN_DOCS}
                />
                <MobileHeaderNavItem
                    label="Ecosystem"
                    active={activeMenu === 1}
                    handler={() => handleMenu(1)}
                >
                    <SubMenuItem
                        title="EON Ecosystem"
                        link={URLProvider.URL_HORIZEN_EON}
                        openNewTab
                    />
                    <SubMenuItem title="ZEN" link={URLProvider.LINK_ZEN} />
                    <SubMenuItem
                        title="Governance"
                        link={URLProvider.URL_HORIZEN_GOVERNANCE}
                    />
                </MobileHeaderNavItem>
                <MobileHeaderNavItem
                    label="Updates"
                    href={URLProvider.URL_HORIZEN_BLOG}
                />
            </div>
        </div>
    )
}

export default MobileNavigation
