import React from "react"

type SectionHeadingProps = {
    children: React.ReactNode
}

const SectionHeading: React.FC<SectionHeadingProps> = ({ children }) => {
    return (
        <h2 className="text-white font-bold text-4xl md:text-[2.5625rem] leading-[3rem]">
            {children}
        </h2>
    )
}

export default SectionHeading
