import React from "react"
import SectionHeading from "../../components/SectionHeading"
import ContentContainer from "../../templates/ContentContainer"

const PrivacyContentItem: React.FC = ({ children }) => {
    return (
        <p className="text-lg text-horizen-content-grey pt-4 pb-8">
            {children}
        </p>
    )
}

const Subheading: React.FC = ({ children }) => {
    return (
        <span className="block text-[22px] font-bold text-white mt-6 mb-2">
            {children}
        </span>
    )
}

const PrivacyContent = () => {
    return (
        <section className="-mt-14">
            <ContentContainer>
                <div>
                    <p className="pb-8">
                        Horizen Foundation, a Caymans Foundation (“Horizen,”
                        “we” or “us”) is committed to protecting the privacy and
                        security of your personally identifying information. We
                        have prepared this Privacy Policy to explain how we
                        collect, use, protect, and disclose your Personal
                        Information and Usage Data (as those terms are defined
                        below) when you use any of the Horizen websites or
                        services (the “Services”). This Privacy Policy also
                        explains how you can manage your information
                        preferences. By using the Services you consent to this
                        Privacy Policy.
                    </p>
                    <SectionHeading>1. INFORMATION WE COLLECT</SectionHeading>
                    <PrivacyContentItem>
                        <Subheading>PERSONAL INFORMATION</Subheading>
                        When you register for the Services, or utilize the
                        Services, we may collect information that personally
                        identifies you, such as your name, address, mobile
                        telephone number, e-mail addresses and other information
                        that you provide to us (“Personal Information”). You may
                        be asked to provide us with certain Personal Information
                        when you register to use the Services, and at other
                        times. The decision to provide this information is
                        voluntary. However, if you choose not to provide the
                        requested information, you may not be able to use
                        certain features of the Services.
                        <Subheading>USAGE DATA</Subheading>
                        We automatically collect usage information that allows
                        us to collect information regarding how users access and
                        use the Services (“Usage Data”). For example, when you
                        download and use the Services, we automatically collect
                        information on the type of device you use and the device
                        identifier (or “UDID”). Additionally, each time you use
                        the Services, we automatically collect information
                        regarding the type of web browser you use, your
                        location, your operating system, your Internet Service
                        Provider, your IP address, the pages you view on our
                        sites, the time and duration of your visits, crash logs
                        and other information relating to your use of the
                        Services. We use this information to analyze trends,
                        administer the Services, troubleshoot any user problems,
                        and to enhance and update the Services.
                        <Subheading>COOKIES</Subheading>
                        Like many other websites, we use cookies on our sites (a
                        small removable data file that is stored by the web
                        browser on your computer that identifies your computer
                        and browser when you visit our site or use our
                        Services). We do not use cookies to collect Personal
                        Information but rather to improve the quality of the
                        Services. Most web browsers are initially set up to
                        accept cookies. You can reset your web browser to refuse
                        all cookies or to indicate when a cookie is being sent.
                        THE SITE’S COOKIES DO NOT AND CANNOT INFILTRATE A USER’S
                        HARD DRIVE TO COLLECT ANY INFORMATION STORED ON THE HARD
                        DRIVE.
                    </PrivacyContentItem>
                    <SectionHeading>
                        2. HOW WE USE THE INFORMATION WE COLLECT
                    </SectionHeading>
                    <PrivacyContentItem>
                        We use information we collect for internal purposes
                        only. We do not sell or rent information about you. We
                        will not disclose Personal Information to third parties
                        without your consent. We use Usage Data only to monitor
                        and improve the Services. For example, we use Google
                        Analytics to help analyze how users use the Service.
                        Google Analytics uses cookies to collect information
                        such as how often users visit the site, what pages they
                        visit, and what other sites they used prior to coming to
                        the Service. We use the information we get from Google
                        Analytics only to improve our Service. Google Analytics
                        collects only the IP address assigned to you on the date
                        you visit the Service, rather than your name or other
                        personally identifying information. We do not combine
                        the information generated through the use of Google
                        Analytics with your Personal Information. Although
                        Google Analytics plants a persistent Cookie on your web
                        browser to identify you as a unique member the next time
                        you visit the Service, the Cookie cannot be used by
                        anyone but Google. Google’s ability to use and share
                        information collected by Google Analytics about your
                        visits to the Service is restricted by the Google
                        Analytics Terms of Use and the Google Privacy Policy.
                    </PrivacyContentItem>
                    <SectionHeading>
                        3. HOW WE PROTECT THE INFORMATION WE COLLECT
                    </SectionHeading>
                    <PrivacyContentItem>
                        <Subheading>SECURITY</Subheading>
                        Horizen is committed to protecting the security of your
                        information. We employ industry best security measures
                        designed to protect your information from unauthorized
                        access. Regardless of our efforts, no data transmission
                        over the Internet or other network, including any of the
                        Services, can be guaranteed to be 100% secure.
                        Accordingly, we cannot and do not guarantee the absolute
                        security of any information you transmit on or through
                        the Services and any information you transmit is sent at
                        your own risk.
                        <Subheading>
                            COMPLIANCE WITH LAWS AND LAW ENFORCEMENT
                        </Subheading>
                        Horizen cooperates with government and law enforcement
                        officials to enforce and comply with the law. We may
                        therefore disclose Personal Information and Usage Data
                        and any other information about you, if we deem that it
                        is reasonably necessary to: (a) satisfy any applicable
                        law, regulation, legal process (such as a subpoena or
                        court order) or enforceable governmental request; (b)
                        enforce the Terms of Use, including investigation of
                        potential violations thereof; (c) detect, prevent, or
                        otherwise address fraud, security or technical issues;
                        or (d) protect against harm to the rights, property or
                        safety of Horizen, its users or the public as required
                        or permitted by law.
                        <Subheading>
                            YOUR INFORMATION MAY BE MAINTAINED WITHIN THE UNITED
                            STATES
                        </Subheading>
                        Please be aware that we may process and store
                        information in the United States. By using the Services,
                        you agree that the collection, use, transfer, and
                        disclosure of your information and communications will
                        be governed by the applicable laws in the United States.
                        The majority of data we collect is stored on servers
                        located in the United States and Europe. Accordingly, we
                        may transfer and store data outside of your home
                        country. When we transfer data out of the European
                        Economic Area or Switzerland, we make use of the EU-U.S.
                        and Swiss-U.S. Privacy Shield Frameworks, EU-approved
                        standard contractual data protection clauses, binding
                        corporate rules for transfers to data processors, or
                        other appropriate legal mechanisms to safeguard the
                        transfer.
                    </PrivacyContentItem>
                    <SectionHeading>4. MISCELLANEOUS</SectionHeading>
                    <PrivacyContentItem>
                        <Subheading>POLICY TOWARDS MINORS</Subheading>
                        Our Services may not be used by anyone under 18. We do
                        not knowingly collect information on anyone under the
                        age of 18. If you are under the age of 18 you must stop
                        using our Services immediately.
                        <Subheading>ACCURACY</Subheading>
                        You can always review, correct, update, or change your
                        Personal Information. Upon your request, we will: (1)
                        correct, update or change your Personal Information; (2)
                        stop sending you messages if you wish to opt out of our
                        direct marketing efforts; and/or (3) disable your
                        account to prevent any future activity through that
                        account. You may make any of these requests by emailing
                        the request to support@horizen.global. Please do not
                        email any sensitive information.
                        <Subheading>RETENTION</Subheading>
                        We retain information only for so long as it continues
                        to help us monitor or improve the Services. The exact
                        length depends on the type of information collected and
                        processed. When Personal Information or Usage Data is no
                        longer needed it will be automatically deleted or
                        anonymized.
                        <Subheading>COMPLIANCE WITH GDPR</Subheading>
                        To the extent required by law, Horizen complies with the
                        EU General Data Protection Regulation (GDPR). GDPR gives
                        users the following rights:
                        <ul className="list-disc list-outside pl-8 py-8">
                            <li>Right of erasure (right to be forgotten)</li>
                            <li>Right of rectification</li>
                            <li>Right to be informed</li>
                            <li>Right of access</li>
                            <li>Right to restrict processing</li>
                            <li>Right to data portability</li>
                            <li>Right to object</li>
                            <li>
                                Right not to be subject to automated decision
                                making
                            </li>
                            <li>
                                Right to complain to a supervisory authority
                            </li>
                        </ul>
                        If you do not believe that Horizen is in compliance with
                        GDPR, please email your concerns to support@horizen.io.
                        <Subheading>LINKS TO OTHER SITES</Subheading>
                        Please be aware that the Services may contain links to
                        other sites that are not governed by this Privacy
                        Policy. Horizen is not responsible for the content or
                        practices of these sites. We encourage you to review the
                        privacy policy of each site visited before disclosing
                        any personal information.
                        <Subheading>CHANGES TO THE PRIVACY POLICY</Subheading>
                        We may periodically change this Privacy Policy. If we
                        decide to change this Privacy Policy, we will inform you
                        by posting the revised Privacy Policy on the Site. Those
                        changes become effective upon posting on our site, the
                        date of which is shown as the “Revised On” date in the
                        revised Privacy Policy. Use of information we collect
                        now is subject to the Privacy Policy in effect at the
                        time such information is collected. By continuing to use
                        the Services, you agree to be bound by the revised
                        Privacy Policy.
                        <Subheading>CONTACT INFORMATION</Subheading>
                        If you have questions or concerns regarding this Privacy
                        Policy, please e-mail them to info@horizen.global.
                        Please send physical mail to: <br />
                        <br />
                        Horizen Foundation <br />
                        3119 9 Forum Lane, <br />
                        Camana Bay P.O. Box 144, <br />
                        Grand Cayman KY1- 9006, Cayman Islands
                    </PrivacyContentItem>
                </div>
            </ContentContainer>
        </section>
    )
}

export default PrivacyContent
