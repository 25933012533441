import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import ContentContainer from "../templates/ContentContainer"
import HeroHeading from "./HeroHeading"
import HeroBodyText from "./HeroBodyText"

export type HeroProps = {
    headingKey?: string
    titleKey: string
    subtitleKey?: string
    img?: React.ReactElement
}

const Hero = ({ headingKey, titleKey, subtitleKey, img: Img }: HeroProps) => {
    const { formatMessage } = useIntl()

    return (
        <ContentContainer>
            <div className="text-center relative">
                {headingKey && (
                    <div className="md:absolute pb-4 md:pb-0 font-bold tracking-[0.2em] text-horizen-grey text-[22px] -top-16 w-full">
                        {formatMessage({
                            id: headingKey,
                        })}
                    </div>
                )}
                {Img && (
                    <div className="md:absolute pb-4 md:pb-0 font-bold tracking-[0.2em] text-horizen-grey text-[22px] -top-24 w-full flex justify-center">
                        {Img}
                    </div>
                )}
                <HeroHeading>
                    {formatMessage({
                        id: titleKey,
                    })}
                </HeroHeading>
                <HeroBodyText>
                    {subtitleKey && formatMessage({ id: subtitleKey })}
                </HeroBodyText>
            </div>
            <div className="flex justify-center pb-14 lg:pb-20">
                <div className="border-b w-12 mt-5" />
            </div>
        </ContentContainer>
    )
}

export default Hero
