import React from "react"
import sendGAEvent, { GAEventName } from "../../utils/sendGAEvent"

const XLogo = ({
    className = "",
    backgroundFillColor = "#0E9DE5",
    backgroundFillClassName = "",
    fillColor = "white",
    width = "47",
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            width={width}
            height={width}
            viewBox={`0 0 ${width} ${width}`}
            fill="none"
        >
            <a
                href="https://twitter.com/horizenglobal"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                    sendGAEvent({
                        eventName: GAEventName.IconClick,
                        eventParameters: {
                            value: "top_x",
                        },
                    })
                }
            >
                <g clipPath="url(#clip0_271_406)">
                    <path
                        d="M17.0005 33.9999C20.3627 33.9999 23.6494 33.0028 26.445 31.1347C29.2406 29.2667 31.4194 26.6116 32.706 23.5053C33.9927 20.3989 34.3293 16.9808 33.6733 13.6832C33.0173 10.3855 31.3981 7.35648 29.0206 4.97902C26.6431 2.60156 23.614 0.982496 20.3163 0.326645C17.0187 -0.329292 13.6006 0.00737127 10.4943 1.29414C7.388 2.58083 4.73299 4.75981 2.86498 7.5554C0.997055 10.351 0 13.6377 0 17C0 19.2325 0.439718 21.4431 1.29406 23.5058C2.14839 25.5683 3.40064 27.4424 4.97928 29.0211C6.55791 30.5996 8.43209 31.8519 10.4947 32.7061C12.5573 33.5605 14.768 34.0001 17.0005 33.9999Z"
                        fill={backgroundFillColor}
                        className={backgroundFillClassName}
                    />
                    <path
                        d="M18.9399 15.3994L26.5308 6.57568H24.732L18.1408 14.2372L12.8765 6.57568H6.80469L14.7654 18.1614L6.80469 27.4145H8.60357L15.564 19.3236L21.1236 27.4145H27.1954L18.9399 15.3994ZM16.476 18.2634L15.6694 17.1097L9.25162 7.92988H12.0147L17.1939 15.3384L18.0005 16.492L24.7328 26.1219H21.9698L16.476 18.2634Z"
                        fill={fillColor}
                    />
                </g>
            </a>
        </svg>
    )
}

export default XLogo
