import React from "react"
import { Helmet } from "react-helmet"

const PrivacyHelmet = () => {
    return (
        <Helmet>
            <title>Horizen - Privacy Policy</title>
            <meta property="og:title" content="Horizen - Privacy Policy" />
            <meta name="twitter:title" content="Horizen - Privacy Policy" />
            <meta name="theme-color" content="#041742" />
            <meta
                name="description"
                content="Horizen's privacy policy outlines how we collect, use, protect, and disclose personal information and usage data."
            />
            <meta
                property="og:description"
                content="Horizen's privacy policy outlines how we collect, use, protect, and disclose personal information and usage data."
            />
            <meta
                name="twitter:description"
                content="Horizen's privacy policy outlines how we collect, use, protect, and disclose personal information and usage data."
            />
            <meta
                name="keywords"
                content="horizen privacy policy, zen privacy policy, zencash privacy policy, zen cash privacy policy"
            />
            <link rel="canonical" href="https://www.horizen.io/privacy/" />
            <meta property="og:url" content="https://www.horizen.io/privacy/" />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Horizen" />
            <meta name="twitter:site" content="@horizenglobal" />
            <meta name="twitter:creator" content="@horizenglobal" />
        </Helmet>
    )
}

export default PrivacyHelmet
