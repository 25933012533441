import React from "react"

import XLogo from "../../assets/Logos/x"
import GithubLogo from "../../assets/Logos/github"
import DiscourseLogo from "../../assets/Logos/discourse"
import DiscordLogo from "../../assets/Logos/discord"

const SocialNetworks = () => {
    return (
        <div className="grid grid-cols-4 gap-x-3 pl-3 justify-items-center md:justify-items-end -mt-1">
            <XLogo backgroundFillColor="#003687" fillColor="white" width="35" />
            <DiscordLogo
                backgroundFillColor="#003687"
                fillColor="white"
                width="37"
            />
            <GithubLogo
                backgroundFillColor="#003687"
                fillColor="white"
                width="37"
            />
            <DiscourseLogo
                backgroundFillColor="#003687"
                fillColor="white"
                width="37"
            />
        </div>
    )
}

export default SocialNetworks
