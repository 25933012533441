import React from "react"
import sendGAEvent, { GAEventName } from "../../utils/sendGAEvent"

const DiscourseLogo = ({
    className = "",
    backgroundFillColor = "#0E9DE5",
    backgroundFillClassName = "",
    fillColor = "white",
    width = "47",
}) => {
    return (
        <svg
            className={className}
            width={width}
            height={width}
            viewBox={`0 0 ${width} ${width}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <a
                href="https://horizen.discourse.group"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                    sendGAEvent({
                        eventName: GAEventName.IconClick,
                        eventParameters: {
                            value: "top_discourse",
                        },
                    })
                }
            >
                <path
                    d="M17.0074 34.0156C20.3712 34.0157 23.6595 33.0183 26.4565 31.1495C29.2534 29.2808 31.4334 26.6246 32.7208 23.5168C34.0082 20.4091 34.345 16.9894 33.6889 13.6902C33.0327 10.391 31.413 7.36044 29.0345 4.9818C26.6559 2.60316 23.6255 0.983231 20.3263 0.326893C17.0271 -0.329446 13.6074 0.0072912 10.4996 1.29448C7.39183 2.58167 4.73553 4.76154 2.86661 7.5584C0.997689 10.3553 9.88941e-05 13.6435 2.64606e-09 17.0073C-3.93942e-05 19.2408 0.439854 21.4525 1.29456 23.516C2.14926 25.5796 3.40203 27.4546 4.98135 29.0339C6.56068 30.6133 8.43561 31.8661 10.4991 32.7209C12.5626 33.5756 14.7743 34.0156 17.0078 34.0156"
                    fill={backgroundFillColor}
                    className={backgroundFillClassName}
                />
                <path
                    d="M17.5901 6C23.3328 6 28 10.7994 28 16.4974C28 22.1954 23.3362 26.9886 17.5875 26.9886L7 27V16.3162C7 10.62 11.8405 6 17.5901 6ZM17.6916 9.99263C15.4236 9.99015 13.3199 11.1753 12.1467 13.1164C10.9827 15.0492 10.9334 17.455 12.0173 19.4339L10.85 23.1588L15.0448 22.219C17.4452 23.2916 20.2556 22.8115 22.1638 21.0028C24.0628 19.2057 24.6661 16.4283 23.6836 14.0054C22.6904 11.5751 20.324 9.98884 17.6986 9.99351H17.6916V9.99263Z"
                    fill={fillColor}
                />
            </a>
        </svg>
    )
}

export default DiscourseLogo
