import React from "react"

const HeroHeading: React.FC = ({ children }) => {
    return (
        <span className="text-white font-bold text-4xl md:text-6xl mt-5">
            {children}
        </span>
    )
}

export default HeroHeading
