import React from "react"
import sendGAEvent, { GAEventName } from "../../utils/sendGAEvent"

const GithubLogo = ({
    className = "",
    backgroundFillColor = "#0E9DE5",
    backgroundFillClassName = "",
    fillColor = "white",
    width = "47",
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            width={width}
            height={width}
            viewBox={`0 0 ${width} ${width}`}
            fill="none"
        >
            <a
                href="https://github.com/horizenofficial"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                    sendGAEvent({
                        eventName: GAEventName.IconClick,
                        eventParameters: {
                            value: "top_git",
                        },
                    })
                }
            >
                <path
                    d="M17.0702 34.0313C20.434 34.0313 23.7222 33.0338 26.5191 31.1649C29.316 29.2961 31.496 26.6399 32.7833 23.5322C34.0706 20.4244 34.4074 17.0048 33.7512 13.7056C33.095 10.4064 31.4752 7.37588 29.0967 4.99728C26.7181 2.61867 23.6877 0.998796 20.3885 0.34249C17.0894 -0.313816 13.6697 0.022938 10.5619 1.31014C7.45414 2.59734 4.79783 4.7772 2.92892 7.57406C1.06002 10.3709 0.0624768 13.6592 0.0623779 17.023C0.0623385 19.2565 0.502201 21.4682 1.3569 23.5317C2.21161 25.5952 3.46441 27.4702 5.04373 29.0496C6.62305 30.629 8.49799 31.8818 10.5615 32.7365C12.625 33.5913 14.8366 34.0312 17.0702 34.0313Z"
                    fill={backgroundFillColor}
                    className={backgroundFillClassName}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.0694 4.51093C14.0227 4.51134 11.0757 5.59585 8.75551 7.57037C6.43534 9.54489 4.89346 12.2806 4.40581 15.2879C3.91816 18.2952 4.51656 21.378 6.09389 23.9845C7.67123 26.591 10.1246 28.5512 13.0149 29.5143C13.6564 29.632 13.8906 29.2359 13.8906 28.8959C13.8906 28.5913 13.8795 27.7845 13.8732 26.7139C10.3045 27.4892 9.55144 24.994 9.55144 24.994C9.31568 24.2185 8.81044 23.5529 8.12694 23.1174C6.96214 22.3213 8.21517 22.3373 8.21517 22.3373C8.62178 22.3935 9.01017 22.5419 9.35073 22.771C9.69129 23.0001 9.97501 23.3041 10.1803 23.6595C10.354 23.9761 10.5888 24.255 10.8711 24.4802C11.1534 24.7054 11.4775 24.8724 11.8247 24.9715C12.1719 25.0706 12.5354 25.1 12.894 25.0577C13.2526 25.0155 13.5993 24.9026 13.914 24.7255C13.9725 24.075 14.2614 23.4668 14.7286 23.0104C11.8797 22.6866 8.88466 21.5859 8.88466 16.6696C8.866 15.395 9.33891 14.1621 10.2052 13.2269C9.81383 12.1204 9.85878 10.9063 10.331 9.83167C10.331 9.83167 11.4083 9.48665 13.8591 11.1469C15.9616 10.5711 18.1802 10.5711 20.2828 11.1469C22.7322 9.48685 23.8074 9.83167 23.8074 9.83167C24.2808 10.9059 24.3265 12.1202 23.935 13.2269C24.8015 14.1619 25.2739 15.3951 25.2537 16.6696C25.2537 21.5982 22.2537 22.6827 19.396 23.0004C19.7021 23.3115 19.9382 23.6844 20.0884 24.0942C20.2386 24.5039 20.2995 24.941 20.267 25.3762C20.267 27.0913 20.2514 28.4752 20.2514 28.8958C20.2514 29.2389 20.4823 29.6381 21.1334 29.5128C24.0226 28.5477 26.4742 26.5863 28.0498 23.9794C29.6254 21.3725 30.2222 18.2901 29.7334 15.2835C29.2447 12.2769 27.7024 9.5422 25.3822 7.56854C23.062 5.59489 20.1154 4.51102 17.0694 4.51074V4.51093Z"
                    fill={fillColor}
                />
            </a>
        </svg>
    )
}

export default GithubLogo
