import React from "react"
import NormalPageHero from "../../components/NormalPageHero"

import * as styles from "../../styles/privacy/privacy.module.css"

const PrivacyHero = () => {
    return (
        <section
            className={`pt-[9rem] md:pt-[calc(4rem+150px)] ${styles.privacyHero}`}
        >
            <NormalPageHero titleKey="privacy.hero.sectionHeading" />
        </section>
    )
}

export default PrivacyHero
