import React from "react"
import { Helmet } from "react-helmet"
import { QueryClient, QueryClientProvider } from "react-query"
import Footer from "../components/Footer"
import Header from "../components/Header"

const queryClient = new QueryClient()

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <QueryClientProvider client={queryClient}>
            <div className="font-proxima" style={{ overflowAnchor: "none" }}>
                <Helmet>
                    <link
                        rel="preload"
                        as="style"
                        href="https://use.typekit.net/elr1akh.css"
                    />
                    <link
                        rel="stylesheet"
                        href="https://use.typekit.net/elr1akh.css"
                    />
                    <meta property="og:url" content="https://horizen.io/" />
                    <meta property="og:type" content="website" />
                    <meta property="og:site_name" content="Horizen" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:site" content="@horizenglobal" />
                    <meta name="twitter:creator" content="@horizenglobal" />
                    <meta
                        name="keywords"
                        content="horizen, zencash, zen, zen coin, zen crypto, zk snarks, zero knowledge blockchain, blockchain ecosystem, horizen global, zen cash"
                    />
                </Helmet>
                <Header />
                {children}
                <Footer />
            </div>
        </QueryClientProvider>
    )
}

export default Layout
