import React from "react"
import NewsletterForm from "../components/NewsletterForm"
import Layout from "../templates/Layout"
import LatestUpdatesBackground from "../assets/LatestUpdatesBackground.png"
import PrivacyHelmet from "../components/SEO/PrivacyHelmet"
import PrivacyHero from "../sections/privacy/PrivacyHero"
import PrivacyContent from "../sections/privacy/PrivacyContent"

const Terms = () => {
    return (
        <Layout>
            <PrivacyHelmet />
            <div className="bg-horizen-hp-bg text-white">
                <PrivacyHero />
                <PrivacyContent />
                <div
                    className="pt-80 -mt-48"
                    style={{
                        backgroundImage: `url(${LatestUpdatesBackground})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "50% 65%",
                    }}
                >
                    <NewsletterForm />
                </div>
            </div>
        </Layout>
    )
}

export default Terms
