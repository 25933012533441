import React from "react"

const HeroBodyText: React.FC = ({ children }) => {
    return (
        <p className="text-base md:text-lg text-horizen-content-grey max-w-3xl mx-auto my-5">
            {children}
        </p>
    )
}

export default HeroBodyText
